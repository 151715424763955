import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { DndContext } from "@dnd-kit/core";
import { format, isValid, parseISO } from "date-fns";
import { Droppable } from "./Droppable";
import { Draggable } from "./Draggable";
import "./App.scss";
import EditTaskForm from "./EditTaskForm";

function App() {
  const [tasks, setTasks] = useState({
    urgent: [],
    important: [],
    normal: [],
    completed: [],
  });
  const [newTask, setNewTask] = useState("");
  const [taskUrgency, setTaskUrgency] = useState("normal");
  const [newTaskDueDate, setNewTaskDueDate] = useState("");
  const [newTaskTags, setNewTaskTags] = useState("");
  const [filter, setFilter] = useState("all");
  const [sortBy, setSortBy] = useState("createdAt");
  const [editingTask, setEditingTask] = useState(null);

  useEffect(() => {
    const storedTasks = localStorage.getItem("tasks");
    if (storedTasks) {
      setTasks(JSON.parse(storedTasks));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("tasks", JSON.stringify(tasks));
  }, [tasks]);

  const handleAddTask = (e) => {
    e.preventDefault();
    if (newTask.trim() !== "") {
      const newTaskObj = {
        id: Date.now(),
        content: newTask,
        createdAt: new Date().toISOString(),
        completedAt: null,
        dueDate: newTaskDueDate ? format(newTaskDueDate, "yyyy-MM-dd") : null,
        tags: newTaskTags
          .split(",")
          .map((tag) => tag.trim())
          .filter((tag) => tag !== ""),
      };
      setTasks((prevTasks) => ({
        ...prevTasks,
        [taskUrgency]: [...prevTasks[taskUrgency], newTaskObj],
      }));
      setNewTask("");
      setNewTaskDueDate(null);
      setNewTaskTags("");
    }
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (over) {
      setTasks((prevTasks) => {
        const activeTask = Object.values(prevTasks)
          .flat()
          .find((task) => task.id === active.id);
        const fromList = Object.keys(prevTasks).find((key) =>
          prevTasks[key].some((task) => task.id === active.id)
        );
        const toList = over.id;

        if (fromList === toList) return prevTasks;

        const updatedTask =
          toList === "completed"
            ? { ...activeTask, completedAt: new Date().toISOString() }
            : { ...activeTask, completedAt: null };

        return {
          ...prevTasks,
          [fromList]: prevTasks[fromList].filter(
            (task) => task.id !== active.id
          ),
          [toList]: [...prevTasks[toList], updatedTask],
        };
      });
    }
  };

  const handleDeleteTask = (taskId) => {
    setTasks((prevTasks) => {
      const updatedTasks = { ...prevTasks };
      for (const category in updatedTasks) {
        updatedTasks[category] = updatedTasks[category].filter(
          (task) => task.id !== taskId
        );
      }
      return updatedTasks;
    });
  };

  const handleEditTags = (taskId, newTags) => {
    const updatedTags = newTags
      .split(",")
      .map((tag) => tag.trim())
      .filter((tag) => tag !== "");
    // handleEditTask(taskId, "tags", updatedTags);
  };

  const filteredTasks = Object.fromEntries(
    Object.entries(tasks).map(([category, categoryTasks]) => [
      category,
      categoryTasks.filter((task) => {
        if (filter === "all") return true;
        if (filter === "overdue") {
          return task.dueDate && new Date(task.dueDate) < new Date();
        }
        return task.tags.includes(filter);
      }),
    ])
  );

  const sortedTasks = Object.fromEntries(
    Object.entries(filteredTasks).map(([category, categoryTasks]) => [
      category,
      [...categoryTasks].sort((a, b) => {
        if (sortBy === "createdAt") {
          return new Date(b.createdAt) - new Date(a.createdAt);
        }
        if (sortBy === "dueDate") {
          if (!a.dueDate) return 1;
          if (!b.dueDate) return -1;
          return new Date(a.dueDate) - new Date(b.dueDate);
        }
        return 0;
      }),
    ])
  );
  const handleEditTask = (taskId, updatedTask) => {
    setTasks((prevTasks) => {
      const updatedTasks = { ...prevTasks };
      for (const category in updatedTasks) {
        updatedTasks[category] = updatedTasks[category].map((task) =>
          task.id === taskId ? { ...task, ...updatedTask } : task
        );
      }
      return updatedTasks;
    });
    setEditingTask(null);
  };

  const renderTask = (task, urgency) => (
    <Draggable key={task.id} id={task.id}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          className={`task ${urgency}`}
        >
          <div
            {...provided.dragHandleProps}
            className="drag-handle"
            style={{ cursor: "grab" }}
          >
            ⋮⋮
          </div>
          {editingTask === task.id ? (
            <EditTaskForm
              task={task}
              onSave={(updatedTask) => handleEditTask(task.id, updatedTask)}
              onCancel={() => setEditingTask(null)}
            />
          ) : (
            <>
              <div className="task-header">
                <h3 className="task-title">{task.content}</h3>
                <span className={`task-urgency ${urgency}`}>{urgency}</span>
              </div>
              <div className="task-body">
                <div className="task-dates">
                  <span>
                    Created: {new Date(task.createdAt).toLocaleString()}
                  </span>
                  <span>
                    Due:{" "}
                    {task.dueDate
                      ? format(parseISO(task.dueDate), "MMMM d, yyyy")
                      : "Not set"}
                  </span>
                  {task.completedAt && (
                    <span>
                      Completed: {new Date(task.completedAt).toLocaleString()}
                    </span>
                  )}
                </div>
                <div className="task-tags">
                  Tags: {task.tags.join(", ") || "No tags"}
                </div>
              </div>
              <div className="task-actions">
                <button onClick={() => setEditingTask(task.id)}>Edit</button>
                <button onClick={() => handleDeleteTask(task.id)}>
                  Delete
                </button>
              </div>
            </>
          )}
        </div>
      )}
    </Draggable>
  );
  // if (editingTask) {
  //   return <div>hiiii</div>;
  //   return (
  //     <EditTaskForm
  //       task={editingTask}
  //       onSave={(updatedTask) => handleEditTask(editingTask.id, updatedTask)}
  //       onCancel={() => setEditingTask(null)}
  //     />
  //   );
  // }

  return (
    <DndContext onDragEnd={handleDragEnd}>
      <div className="app-container">
        <header>
          <h1>Instant Taskify</h1>
        </header>
        <main>
          <form onSubmit={handleAddTask} className="task-form">
            <div className="form-group">
              <label htmlFor="task-input">Task Description</label>
              <input
                id="task-input"
                type="text"
                value={newTask}
                onChange={(e) => setNewTask(e.target.value)}
                placeholder="Enter new task"
                className="task-input"
              />
            </div>
            <div className="form-group">
              <label htmlFor="urgency-select">Urgency</label>
              <select
                id="urgency-select"
                value={taskUrgency}
                onChange={(e) => setTaskUrgency(e.target.value)}
                className="urgency-select"
              >
                <option value="normal">Normal</option>
                <option value="important">Important</option>
                <option value="urgent">Urgent</option>
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="due-date-input">Due Date</label>
              <DatePicker
                selected={newTaskDueDate}
                onChange={(date) => setNewTaskDueDate(date)}
                dateFormat="MMMM d, yyyy"
                minDate={new Date()}
                isClearable
                // showYearDropdown
                scrollableYearDropdown
                yearDropdownItemNumber={10}
                placeholderText="Select a due date"
                className="date-picker-input"
                wrapperClassName="date-picker-wrapper"
              />
            </div>
            <div className="form-group">
              <label htmlFor="tags-input">Tags</label>
              <input
                id="tags-input"
                type="text"
                value={newTaskTags}
                onChange={(e) => setNewTaskTags(e.target.value)}
                placeholder="Enter tags (comma-separated)"
                className="tags-input"
              />
            </div>
            <button type="submit" className="add-button">
              Add Task
            </button>
          </form>

          <div className="filters">
            <select
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
              className="filter-select"
            >
              <option value="all">All Tasks</option>
              <option value="overdue">Overdue Tasks</option>
              {/* Add more filter options based on tags */}
            </select>
            <select
              value={sortBy}
              onChange={(e) => setSortBy(e.target.value)}
              className="sort-select"
            >
              <option value="createdAt">Sort by Created Date</option>
              <option value="dueDate">Sort by Due Date</option>
            </select>
          </div>

          <div className="task-columns">
            <div className="column pending-column">
              <h2>Pending Tasks</h2>
              <div className="urgency-columns">
                <Droppable id="urgent" className="urgency-column urgent">
                  <h3>Urgent</h3>
                  {sortedTasks.urgent.map((task) => renderTask(task, "urgent"))}
                </Droppable>

                <Droppable id="important" className="urgency-column important">
                  <h3>Important</h3>
                  {sortedTasks.important.map((task) =>
                    renderTask(task, "important")
                  )}
                </Droppable>

                <Droppable id="normal" className="urgency-column normal">
                  <h3>Normal</h3>
                  {sortedTasks.normal.map((task) => renderTask(task, "normal"))}
                </Droppable>
              </div>
            </div>

            <Droppable id="completed" className="column completed-column">
              <h2>Completed Tasks</h2>
              {sortedTasks.completed.map((task) =>
                renderTask(task, "completed")
              )}
            </Droppable>
          </div>
        </main>
      </div>
    </DndContext>
  );
}

export default App;
