import React, { useState } from "react";
import DatePicker from "react-datepicker";
import { format, isValid, parseISO } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";

function EditTaskForm({ task, onSave, onCancel }) {
  const [editedTask, setEditedTask] = useState({
    content: task.content,
    dueDate: task.dueDate ? parseISO(task.dueDate) : null,
    tags: task.tags.join(", "),
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedTask((prev) => ({ ...prev, [name]: value }));
  };

  const handleDateChange = (date) => {
    setEditedTask((prev) => ({ ...prev, dueDate: date }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave({
      ...editedTask,
      dueDate: editedTask.dueDate
        ? format(editedTask.dueDate, "yyyy-MM-dd")
        : null,
      tags: editedTask.tags
        .split(",")
        .map((tag) => tag.trim())
        .filter((tag) => tag !== ""),
    });
  };

  return (
    <form onSubmit={handleSubmit} className="edit-task-form">
      <div className="form-group">
        <label htmlFor="content">Task Description</label>
        <input
          id="content"
          name="content"
          type="text"
          value={editedTask.content}
          onChange={handleChange}
          required
        />
      </div>
      <div className="form-group">
        <label htmlFor="dueDate">Due Date</label>
        <DatePicker
          selected={editedTask.dueDate}
          onChange={handleDateChange}
          dateFormat="MMMM d, yyyy"
          minDate={new Date()}
          isClearable
          // showYearDropdown
          scrollableYearDropdown
          yearDropdownItemNumber={10}
          placeholderText="Select a due date"
          className="date-picker-input"
          wrapperClassName="date-picker-wrapper"
        />
      </div>
      <div className="form-group">
        <label htmlFor="tags">Tags</label>
        <input
          id="tags"
          name="tags"
          type="text"
          value={editedTask.tags}
          onChange={handleChange}
          placeholder="Enter tags (comma-separated)"
        />
      </div>
      <div className="form-actions">
        <button type="submit">Save</button>
        <button type="button" onClick={onCancel}>
          Cancel
        </button>
      </div>
    </form>
  );
}

export default EditTaskForm;
